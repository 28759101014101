<template>
  <!-- 
  父组件props传入chartType图表类型使用相应样式,
  组件添加ref, 父组件调用此组件方法
  drawPie(data,title='')/圆饼图 data 传入echarts series对象
  drawPies(data,title='')/多圆饼图 data 传入echarts dataset的 source对象
 -->
  <div
    style="margin-left: 1.4166667rem;"
    :class="chartType == 'ring' ? 'ring'
        : chartType == 'bar'
        ? 'bar'
        : chartType == 'rings'
        ? 'rings'
        : chartType == 'pie'
        ? 'pie'
        : 'default'
    "
    :id="this.echartsId"
  ></div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart, BarChart } from "echarts/charts.js";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  DatasetComponentOption,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

// 饼状图参数

export default {
  props: ["chartType"],
  data() {
    return {
      echartsId: null,
      chart: null,
      chartType: "",
    };
  },

  methods: {
    drawPie(data, title = "") {
      let chartTitle = {};
      if (title != "") {
        chartTitle = {
          subtext: title,
          x: "center", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          bottom: "20%",
          textAlign: "center",
        };
      }

      let series = {
        type: "pie",
        radius: 80,
        center: ["50%", "50%"],
        tooltip: { name: "item" },

        data: data,
        emphasis: {},
      };
      let option = {
        title: chartTitle,
        color: [
          "#FD81A7",
          "#F6CD6C",
          "#006EDD",
          "#2589EE",
          "#2678FF",
          "#59ACFF",
          "#ca8622",
          "#bda29a",
          "#6e7074",
          "#546570",
          "#c4ccd3",
        ],
        legend: {
          orient: "vertical",
          right: "right",
          tooltip: {
            show: true,
          },
        },

        tooltip: {
          trigger: "item",
        },
        series: series,
        emphasis: {
          label: {
            //饼图图形上的文本颜色设置
            show: true,
            position: "inner", //标签的位置
            fontWeight: 300,
            fontSize: 12, //文字的字体大小
          },
        },
      };
      this.chart.setOption(option);
      this.chart.hideLoading();
    },
    drawRing(data, title = "") {
      let chartTitle = [];
      if (title != "") {
        chartTitle.push({
          subtext: title,
          x: "center", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          bottom: "20%",
          textAlign: "center",
        });
      }
      if (data.total != null) {
        chartTitle.push({
          text: data.total,
          subtext: "管理总数",
          x: "29%", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          y: "40%",
          textAlign: "center",
        });
      }
      let series = {
        type: "pie",
        radius: [65, 90],
        center: ["30%", "50%"],
        tooltip: { name: "item" },
        data: data.data,
      };
      let option = {
        title: chartTitle,
        color: [
          "#FD81A7",
          "#006EDD",
          "#F6CD6C",
          "#2589EE",
          "#2678FF",
          "#59ACFF",
          "#ca8622",
          "#bda29a",
          "#6e7074",
          "#546570",
          "#c4ccd3",
        ],
        legend: {
          orient: "vertical",
          x: "right",
          y: "center",
          textAlign: "center",
          itemGap: 35,
          height: 218,
          itemWidth: 53,
          itemHeight: 26,
          tooltip: {
            show: true,
          },
        },

        tooltip: {
          trigger: "item",
        },
        series: series,
      };
      this.chart.setOption(option);
      this.chart.hideLoading();
    },
    drawLine(data) {
      let option = {
        xAxis: data.xAxis,
        yAxis: {
          type: "value",
        },
        emphasis: {
          label: {
            //饼图图形上的文本颜色设置
            show: true,
            position: "inner", //标签的位置
            fontWeight: 300,
            fontSize: 12, //文字的字体大小
          },
        },
        tooltip: { name: "item" },

        series: data.series,
      };
      this.chart.setOption(option);
      this.chart.hideLoading();
    },
    drawPies(data) {
      let series = [];
      let title = [];
      for (let i = 1; i < data[0].length; i++) {
        title.push({
          subtext: data[0][i],
          x:
            100 / (data[0].length - 1) / 2 +
            (100 / (data[0].length - 1)) * (i - 1) -
            1 +
            "%", //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
          bottom: "20%",
          textAlign: "center",
        });
        series.push({
          type: "pie",
          radius: 90,
          center: [
            100 / (data[0].length - 1) / 2 +
              (100 / (data[0].length - 1)) * (i - 1) -
              1 +
              "%",
            "40%",
          ],
          tooltip: { name: "item" },
          encode: {
            itemName: data[0][0],
            value: data[0][i],
          },
          emphasis: {
            label: {
              //饼图图形上的文本颜色设置
              show: true,
              position: "inner", //标签的位置
              fontWeight: 300,
              fontSize: 12, //文字的字体大小
            },
          },
        });
      }
      let option = {
        title: title,
        color: [
          "#FD81A7",
          "#F6CD6C",
          "#006EDD",
          "#2589EE",
          "#2678FF",
          "#59ACFF",
          "#ca8622",
          "#bda29a",
          "#6e7074",
          "#546570",
          "#c4ccd3",
        ],
        legend: {
          orient: "vertical",
          bottom: "bottom",
          tooltip: {
            show: true,
          },
        },

        tooltip: {
          trigger: "item",
        },
        dataset: [
          {
            source: data,
          },
        ],
        series: series,
      };
      this.chart.setOption(option);
      this.chart.hideLoading();
    },
    drawRings(data) {
      let series = [];
      let title = [];
      for (let i = 1; i < data.data[0].length; i++) {
        title.push({
          subtext: data.data[0][i],
          x:
            100 / (data.data[0].length - 1) / 2 +
            (100 / (data.data[0].length - 1)) * (i - 1) -
            0.5 +
            "%",
          bottom: "20%",
          textAlign: "center",
        });
        title.push({
          text: data.total[i - 1],
          subtext: data.data[0][i] + "总数",
          x:
            100 / (data.data[0].length - 1) / 2 +
            (100 / (data.data[0].length - 1)) * (i - 1) -
            0.5 +
            "%",
          y: "35%",
          textAlign: "center",
        });
        series.push({
          type: "pie",
          radius: [60, 80],
          center: [
            100 / (data.data[0].length - 1) / 2 +
              (100 / (data.data[0].length - 1)) * (i - 1) +
              "%",
            "40%",
          ],
          tooltip: { name: "item" },
          encode: {
            itemName: data.data[0][0],
            value: data.data[0][i],
          },
        });
      }

      let option = {
        title: title,
        color: [
          "#FD81A7",
          "#F6CD6C",
          "#006EDD",
          "#2589EE",
          "#2678FF",
          "#59ACFF",
          "#ca8622",
          "#bda29a",
          "#6e7074",
          "#546570",
          "#c4ccd3",
        ],
        legend: {
          orient: "vertical",
          bottom: "bottom",
          itemGap: 35,
          itemWidth: 53,
          itemHeight: 26,

          tooltip: {
            show: true,
            name: "item",
          },
        },

        tooltip: {
          trigger: "item",
        },
        dataset: {
          source: data.data,
        },
        series: series,
      };

      this.chart.setOption(option);
      this.chart.hideLoading();
    },
    drawLines(data) {
      let option = {
        xAxis: data.xAxis,
        yAxis: {
          type: "value",
        },
        series: data.series,
      };
      this.chart.setOption(option);
      this.chart.hideLoading();
    },
    initCharts() {
      this.chart = echarts.init(document.getElementById(this.echartsId));
    },
    loading() {
      this.chart.showLoading();
    },
  },
  created() {
    this.echartsId = "echarts" + Math.random() * 10000;
  },
  mounted() {
    this.initCharts();
    // this.drawCharts();
  },
};
</script>

<style>
.default {
  width: 50rem;
  height: 36.6666667rem;
}
.bar {
  width: 123.33333rem;
  height: 50rem;
  display: block;
}
.pie {
  width: 50rem;
  height: 36.6666667rem;
}
.ring {
  width: 47.916667rem;
  height: 18.6667rem;
}
.rings {
  width: 86.666667rem;
  height: 31.6667rem;
}
</style>