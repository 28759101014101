<template>
  <el-upload
    class="avatar-uploader"
    :action="domain"
    :show-file-list="false"
    :on-success="UploadFaceImg"
    :data="QiNiYunL"
    :before-upload="gettoken"
  >
    <el-image v-if="img" :src="img" alt="" class="avatar" :style="customStyle"/>
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import {getPicKeyName} from "@/utils/utils.js"
export default {
  props: ["clear", "url","customStyle"],
  data() {
    return {
      img: "",
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "https://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
    };
  },
  methods: {
    reset() {
      this.img = "";
    },
    UploadFaceImg(file) {
      this.img = this.upload_qiniu_addr + file.key;
      this.$emit("getImgUrl", this.img);
      console.log(this.img);
    },
     gettoken() {
      let _this = this;
      var timestamp = getPicKeyName()
      let data = {
        key: timestamp,
        type: "pic",
      };

      return new Promise((resolve,reject)=>{
          _this
        .$axios({
          method: "post",
          url: "https://www.curiousmore.com/qmore/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
          resolve()
        })
        .catch((err) => {
          console.log(err);
          reject()
        });
      })

      
    },
  },

  watch: {
    url:{
      handler(nv){
         this.img = nv;
      },
      immediate:true
    },
    clear(newV, oldV) {
      if (newV) {
        // console.log(newV);
        this.img = "";
      }
    },
  },
};
</script>

<style lang="less">
/deep/.avatar-uploader .el-upload{
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 14px;
  color: #8c939d;
  width: 5.6rem ;
  height: 5.6rem ;
  line-height: 5.6rem ;
  text-align: center;
}
.avatar {
  width: 5.6rem ;
  height: 5.6rem ;
  display: block;
  /* margin-right:0px; */
}
</style>
