<template>
  <el-row class="user-total">
    <div
      v-for="key in dataType[orgType] ? dataType[orgType] : organizations"
      :key="key"
      :span="4"
    >
      <el-image
        lazy
        :src="require('../../assets/imgs/' + imgs[key].img)"
        alt=""
        class="col-5"
      />
      <div class="text">
        <div>
          <span>{{ imgs[key].name }}总数</span>
          <span :style="'color:' + imgs[key].color">{{
            screenInfos[key].amount
          }}</span>
        </div>
        <div v-if="screenInfos[key].online !== undefined">
          <span> 当前在线数 </span>
          <span :style="'color:' + imgs[key].color">{{
            screenInfos[key].online
          }}</span>
        </div>
        <div v-if="screenInfos[key].newAdd !== undefined">
          <span> 昨日新增 </span>
          <span :style="'color:' + imgs[key].color">{{
            screenInfos[key].newAdd
          }}</span>
        </div>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  props: ["orgType"],
  data() {
    return {
      // 展示的项目
      dataType: {
        total: ["edu", "sch", "mec", "tea", "cla", "use"],
        teach_paypal: ["edu", "mec", "tea", "cla", "use"],
        // school: ['edu', 'sch', 'mec', 'tea', 'cla', 'use'],
        education: ["edu"],
        mechanism: ["mec"],
        school: ["edu", "sch", "tea", "cla", "use"],
        wesSocket: null,
      },
      screenInfos: {
        edu: { amount: "-", newAdd: "-", online: 0 },
        sch: { amount: "-", newAdd: "-", online: 0 },
        mec: { amount: "-", newAdd: "-", online: 0 },
        tea: { amount: "-", newAdd: "-", online: 0 },
        cla: { amount: "-", newAdd: "-", online: 0 },
        use: { amount: "-", online: 0 },
      },
      // 样式
      imgs: {
        edu: { name: "教育局", img: "教育局.png", color: "#298DF7" },
        sch: { name: "管理学校", img: "学校.png", color: "#FF6283" },
        mec: { name: "管理机构", img: "机构.png", color: "#4fc5ea" },
        tea: { name: "管理教师", img: "老师管理.png", color: "#6781C8" },
        cla: { name: "课程", img: "课程管理.png", color: "#67C89E" },
        use: { name: "学生", img: "学生.png", color: "#f07700" },
      },
      // 数据

      organizations: ["edu", "sch", "mec", "tea", "cla", "use"],
    };
  },
  methods: {
    init() {},

    getBigScreenViewData() {
      let url = "/user/mastermechanism/queryBigScreenDetailNew";
      // this.wesSocket = new WebSocket("wss://echo.websocket.org/");
      this.$axios
        .get(url, {
          params: {
            status: 2,
          },
        })
        .then((res) => {
          // console.log(res);
          this.screenInfos.cla.amount = res.data.data.classCount;
          this.screenInfos.cla.newAdd = res.data.data.yesterdayClassCount;
          this.screenInfos.tea.amount = res.data.data.masterCount;
          this.screenInfos.edu.amount = res.data.data.eucationCount;
          this.screenInfos.edu.newAdd = res.data.data.yesterdayEucationCount;
          this.screenInfos.mec.amount = res.data.data.mechanismCount;
          this.screenInfos.mec.newAdd = res.data.data.yesterdayMechanismCount;
          this.screenInfos.tea.newAdd = res.data.data.yesterdayClassCount;
          this.screenInfos.sch.amount = res.data.data.schoolCount;
          this.screenInfos.sch.newAdd = res.data.data.yesterdaySchoolCount;
          this.screenInfos.use.amount = res.data.data.userTotal;
          this.screenInfos.use.amount = res.data.data.studentCount;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
  mounted() {
    this.getBigScreenViewData();
  },
  destroyed() {},
};
</script>

<style>
.el-row.user-total {
  display: flex;
  align-items: center;
}
.el-row.user-total > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 5.8rem;
  min-width: 17.45rem;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.07);
  padding: 1rem 1.5rem;
  margin-right: 2rem;
}

.el-row.user-total {
  width: 100%;
  margin-bottom: 3rem;
  /* margin-left: 3rem; */
}
.el-row.user-total img {
  width: 3rem;
  margin-right: 1rem;
}
.el-row.user-total .text div {
  font-size: 1.1rem;
}
.el-row.user-total .text div span:first-child {
  display: inline-block;
  width: 7rem;
  text-align: right;
}
.el-row.user-total .text div span:nth-child(2) {
  font-size: 1.7rem;
  margin-left: 0.5rem;
}
</style>
