<template>
  <div>
    <el-table
      ref="tb"
      :data="DataList"
      :fit="true"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center' }"
      :v-loading="loading"
      :row-key="rowKey"
      @selection-change="selectionChange"
    >
      <el-table-column
        v-for="(opt, index) in option"
        :key="index"
        :label="opt.name"
        :prop="opt.value"
        :width="opt.width"
        :type="opt.col_type"
        :reserve-selection="opt.reserveSelection"
      >
        <template v-if="opt.type === 'custom'" v-slot="{ row }">
          <slot :row="row" :name="opt.value"></slot>
        </template>
      </el-table-column>
      <slot></slot>
    </el-table>

    <paging-fy
      v-if="showPage"
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  props: {
    DataList: {
      type: Array,
    },
    option: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    rowKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },

  mounted() {},

  methods: {
    changeCurrentPage(v) {
      this.currentPage = v;
      this.$emit("changePage", v);
    },
    selectionChange(v) {
      this.$emit("selectionChange", v);
    },
    clearAllSelection() {
      this.$refs.tb.clearSelection();
    },
  },
};
</script>

<style lang="scss" scoped></style>