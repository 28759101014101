import addrSelect from '@/components/map.vue'
import AllEchart from '@/components/AllEcharts.vue'
import avatarUploader from '@/components/avatarUploader.vue'
import imagesUploader from '@/components/imagesUploader.vue'
import mechanismSelect from "@/components/select/mechanismSelect.vue";
import userSelect from "@/components/select/userSelect.vue";
import citySelect from "@/components/select/citySelect.vue";
import coachSelect from "@/components/select/coachSelect.vue";
import ContentTitle from '@/components/ContentTitle'
import AllMap from '@/components/allMap.vue'
import pagingfy from '@/components/pagingfy'
import Icon from '@/components/Icon'
import autoTable from '@/components/autoTable/index'
import linkTo from '@/components/text/linkTo.vue'

const components = {
    install(Vue){
Vue.component('paging-fy',pagingfy)
Vue.component('content-title', ContentTitle)
Vue.component('all-map', AllMap)
Vue.component('addr-select', addrSelect)
Vue.component('all-echart', AllEchart)
Vue.component('avatar-uploader', avatarUploader)
Vue.component('images-uploader', imagesUploader)
Vue.component('mechanism-select', mechanismSelect)
Vue.component('user-select', userSelect)
Vue.component('city-select', citySelect)
Vue.component('svg-icon', Icon)
Vue.component('auto-table', autoTable)
Vue.component('coach-select', coachSelect)
Vue.component('linkTo', linkTo)


    }
}

export default components