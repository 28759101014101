<template>
    <div class="link-wrap" @click="linkto">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props:{
        to:{
            type:[String,Object],
            default:""
        }
    },
    data() {
        return {
            
        };
    },

    methods: {
        linkto(){
            this.$router.push(this.to);
        }
    },
};
</script>

<style lang="less" scoped>
.link-wrap{
    color:#0090ff;
    &:hover{
        cursor: pointer;
    }
}
</style>