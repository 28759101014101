<template>
  <div>
    <el-select
      filterable
      v-model="mechanism_id"
      :placeholder="placeholder+''"
      clearable
      remote
      :remote-method="searchOrgName"
      @change="changeUser"
      ref="agentSelect"
      @hook:mounted="cancalReadOnly"
      @visible-change="cancalReadOnly"
    >
      <el-option
        v-for="(option, index) in mechanism_name_list"
        :key="index"
        :value="option.id"
        :label="option.mechanism_name"
        @click.native="mecChnage(option)"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Object,
      default: ()=>{return {}},
    },
    placeholder:{
      type:[String,Number],
      default:"请输入名称"
    }
  },
  data() {
    return {
      mechanism_name_list: [],
      selected_user: "",
      mechanism_id: "",
    };
  },
  methods: {
    clear(){
        this.mechanism_name_list= []
        this.mechanism_id= ""
      },
    mecChnage(item) {
      this.$emit("change", item);
    },
    changeUser() {
      this.$emit("update:mecid", this.mechanism_id);
    },
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryByMessage";
      const numberReg = /^\d/
      let data = {
        type: "teach_paypal",
        pageSize: 10,
        currentPage: 1,
        ...this.condition,
      };
      numberReg.test(query)?(data.mechanism_telephone = query) : (data.mechanism_name = query)
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs
      
　　　　　　// 如果只有1个下拉框，这段就足够了---start
          if (Selects.agentSelect) {
            const input = Selects.agentSelect.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>