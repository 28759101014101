// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import  './router/permission.js'
import axios from 'axios'
import '../public/css/main.css'
import './assets/css/commen.css'

// import Vuex from 'vuex'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import AMapLoader from '@amap/amap-jsapi-loader';

import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts';
echarts.use([LineChart]);
Vue.prototype.$echarts = echarts
// import 'default-passive-events'
import BaiduMap from 'vue-baidu-map';
import globalComponents from '@/global/components.js';
import directive from '@/global/directive.js';

import api from "./api";
Vue.prototype.$get = api.get;
Vue.prototype.$post = api.post;
Vue.prototype.$href = process.env.NODE_ENV == "development" ?'http://192.168.1.179:8768/' : 'http://joingp.net/'
import 'swiper/dist/js/swiper.min';


import './js/flexable.js';
import {uncodeUtf16,utf16toEntities,getPermList,ObjectChangedValue} from './js/utils.js'

import TIM from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';

let options = {
  SDKAppID: 1400782437, 
};


let im = TIM.create(options);
im.registerPlugin({
  "tim-upload-plugin": TIMUploadPlugin,
});
Vue.prototype.$tim = im;
Vue.prototype.$tims = TIM;

Vue.prototype.$login = false
im.setLogLevel(1);
Vue.config.productionTip = false;

Vue.prototype.TimRoll = new Vue();

Vue.prototype.timeToTemp = function(time){
  return new Date(time.replace(/\-/g,"/")).getTime()
} 
//mock
// import './mock/mock.js'

Vue.use(BaiduMap, {
  ak: "4wCTuvgtkfbqkOLQAv9jNFiP6qhYIH0E",
});
let map = AMapLoader.load({
  // key:'0adb8163c160086b4d4187e2ddcbc87f',
  key: "c0c6e86a15067065d61ffb5d309f8308", // 申请好的Web端开发者Key
  version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  plugins: ["AMap.Geocoder","AMap.PlaceSearch","AMap.Autocomplete"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  Loca: {
    // 是否加载 Loca， 缺省不加载
    version: "1.3.2", // Loca 版本，缺省 1.3.2
  },
});
Vue.prototype.$map = map
Date.prototype.Format = function (fmt) {
  var o = {
    "y+": this.getFullYear() , //年份
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

// let base_url = "http://139.186.169.185:8768/eg-api";  //测试
// let base_url = "http://192.168.1.111:8768/eg-api";  // 本地
// let base_url = 'https://www.curiousmore.com/qmore/'; /* 线上 */

// Vue.prototype.$url = base_url
Vue.config.productionTip = false;
axios.defaults.baseURL =  process.env.VUE_APP_BASE_URL

// axios.defaults.baseURL = base_url
// axios.defaults.headers["token"] = localStorage.getItem("token");
// axios.interceptors.response.use(
//   (response) => {
//     if (response.data.code === 30003) {
//       store.dispatch("logout").then(() => {
//         location.reload(true);
//       });
//     }
//     return response;
//   },
//   (error) => {
//     if (error.message == "canceled") {
//     } else {
//       Vue.prototype.$message({
//         type: "error",
//         message: error.data.message,
//       });
//     }
//     return Promise.reject(error);
//   }

// )
 Date.prototype.format = function(fmt) { 
	       var o = { 
            "y+" : this.getFullYear(),
	          "M+" : this.getMonth()+1,                 //月份 
	          "d+" : this.getDate(),                    //日 
	          "h+" : this.getHours(),                   //小时 
	          "m+" : this.getMinutes(),                 //分 
	          "s+" : this.getSeconds(),                 //秒 
	          "q+" : Math.floor((this.getMonth()+3)/3), //季度 
	          "S"  : this.getMilliseconds()             //毫秒 
	      }; 
	      if(/(y+)/.test(fmt)) {
	              fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
	      }
	       for(var k in o) {
	          if(new RegExp("("+ k +")").test(fmt)){
	               fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
	           }
	       }
	      return fmt; 
	  }
    function cloneDeep(obj) {
      var objClone = Array.isArray(obj) ? [] : {};
      if (obj && typeof obj === "object") {
          for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                  if (obj[key] && typeof obj[key] === "object"){
                      objClone[key] = cloneDeep(obj[key]);
                  } else {
                      objClone[key] = obj[key];
                  }
              }
          }
      }
      return objClone;
  }
  Vue.prototype.cloneDeep = cloneDeep
Vue.prototype.uncodeUtf16 = uncodeUtf16
Vue.prototype.utf16toEntities = utf16toEntities
Vue.prototype.getPermList = getPermList
Vue.prototype.ObjectChangedValue = ObjectChangedValue
Vue.prototype.$axios = axios
Vue.prototype.$source = axios.CancelToken.source()
Vue.prototype.$map = map

Vue.use(ElementUI)
Vue.use(globalComponents)
Vue.use(directive)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
