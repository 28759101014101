var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.chartType == 'ring' ? 'ring'
       : _vm.chartType == 'bar'
       ? 'bar'
       : _vm.chartType == 'rings'
       ? 'rings'
       : _vm.chartType == 'pie'
       ? 'pie'
       : 'default',staticStyle:{"margin-left":"1.4166667rem"},attrs:{"id":this.echartsId}})}
var staticRenderFns = []

export { render, staticRenderFns }