<template>
  <div>
    <el-cascader
      v-model="cityList"
      :options="cityoptions"
      :props="cityProps"
      :placeholder="placeholder"
      @change="cityChange"
      clearable
    ></el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "请选择城市",
    },
    temp: {
      type: Array,
      default:()=> [],
    },
    area: {
      type: Boolean,
      default: false,
    },
     checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      cityProps: {
        checkStrictly: this.checkStrictly,
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
    };
  },
  watch: {
    temp: {
      handler: function (nv, ov) {
        this.cityList = nv;
      },
      deep: true,
      immediate: true,
    },
    area: {
      handler: function (nv, ov) {
        if (nv) {
          this.getAddrList();
        }
      },
      immediate: true,
    },
  },

  methods: {
    getAddrList() {
      let list = JSON.parse(localStorage.getItem("addrList"));
      let area = JSON.parse(localStorage.getItem("cur_agentAreas"));
      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (cooperator && area) {
        let province;
        for (let i = 0; i < list.length; i++) {
          if (list[i].district === area.province) {
            province = list[i];
            break;
          }
        }
        if (cooperator.agent_level == "province") {
          this.cityoptions = province.childrens;
        }
        if (cooperator.agent_level == "city") {
          province.childrens.forEach((item) => {
            if (item.district == area.city) {
              this.cityoptions = item.childrens;
            }
          });
        }
      } else {
        this.cityoptions = list;
      }
    },
    cityChange() {
      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      let params = [];
      if (this.area && cooperator) {
        if (this.cityList.length > 0) {
          if (cooperator) {
            let len = this.cityList.length;
            switch (cooperator.agent_level) {
              case "province":
                params = [null,...this.cityList]
                break;
              case "city":
                params = [null,null,...this.cityList]
                break;
              default:
                break;
            }
          }
        }
        this.$emit("change", params);
      } else {
        this.$emit("change", this.cityList);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>