import store from '@/store'
// 将emoji表情其转成 base64
export function utf16toEntities(str) {
  var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
  if (!str) {
    return ''
  }
  str = str.replace(patt, function (char) {
    var H, L, code;
    if (char.length === 2) {
      H = char.charCodeAt(0); // 取出高位
      L = char.charCodeAt(1); // 取出低位
      code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
      return "&#" + code + ";";
    } else {
      return char;
    }
  });
  return str;
}
// 解码emoji表情
export function uncodeUtf16(str) {
  var reg = /\&#.*?;/g;
  if (!str) {
    return ''
  }
  var result = str.replace(reg, function (char) {
    var H, L, code;
    if (char.length == 9) {
      code = parseInt(char.match(/[0-9]+/g));
      H = Math.floor((code - 0x10000) / 0x400) + 0xD800;
      L = (code - 0x10000) % 0x400 + 0xDC00;
      return unescape("%u" + H.toString(16) + "%u" + L.toString(16));
    } else {
      return char;
    }
  });
  return result;
}


export async function getPermList(url) {
  let perm = store.state.permissionList
  if (!perm) {
    perm = await store.dispatch('getUserPermission')
    return hasPermission(perm, url)
  } else {
    return hasPermission(perm, url)
  }
}

function hasPermission(arr, url) {
  if (arr.includes(url)) {
    return true
  } else {
    return false
  }
}
// 原信息 新信息
// 过滤出对象中不相等的键值对
export function ObjectChangedValue(obj1, obj2) {
  let keys = Object.keys(obj2)
  let result = {}
  keys.forEach(k => {
    if (Object.getOwnPropertyDescriptor(obj1, k)) {
      if (obj1[k] != obj2[k]) {
        result[k] = obj2[k]
      }
    } else {
      result[k] = obj2[k]
    }
  })
  return result
}
// 时间差转换成日期
export function get2Time(secondTime) {
  const time = Math.abs(secondTime / 1000);
  let newTime;
  let d = Math.floor(time / (3600 * 24))        //获取天数
  let h = Math.floor((time % (3600 * 24)) / (60 * 60))        //获取时
  let m = Math.floor(((time % (3600 * 24)) % (60 * 60)) / 60)        //获取分
  let s = Math.floor(time % 60)        //获取秒
  d = d < 10 ? "0" + d : d;
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  if (time >= 86400) {
    newTime = `${d}天 ${h}时${m}分${s}秒`;
  } else if (time >= 3600) {
    newTime = `${h}时${m}分${s}秒`;
  } else if (time >= 60 && time < 3600) {
    newTime = `${m}分${s}秒`;
  } else if (time < 60) {
    newTime = `${s}秒`;
  }
  return newTime;
}


// 防抖
export const DebounceBy = (fn, t) => {
  let delay = t || 500
  let timer
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer)
    }

    let callNow = !timer

    timer = setTimeout(() => {
      timer = null
    }, delay)

    if (callNow) fn.apply(this, args)
  }
}