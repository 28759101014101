<template>
    <div class="icon-wrapper" @click="click">
      <svg class="icon" aria-hidden="true" :style="{color,width:size+'px',height:size+'px'}">
        <use :xlink:href="iconName"></use>
      </svg>
    </div>
  </template>
  <script>
  // 引入从iconfont 下载的symbox文件
  import '@/assets/svg/iconfont.js'
  // 引入本地的svg文件
  // 定义一个加载目录的函数
  const requireAll = requireContext => requireContext.keys().map(requireContext)
  const req = require.context('@/assets/svg', false, /\.svg$/)
  // 加载目录下的所有的 svg 文件
  requireAll(req)
   
  export default {
    name: 'Icon',
    props: {
      name: String,
      color:String,
      size:[String,Number],
      prefix: {
        type: String,
        default: 'icon-'
      }
    },
    computed: {
      iconName(){
        return `#${this.prefix}${this.name}`
      }
    },
    methods:{
      click(){
        this.$emit('click')
      }
    }
  }
  </script>
   
  <style lang="less" scoped>
  .icon-wrapper {
    display: inline-block;
  }
  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;

  }
  </style>