export function ObjecToMenu(list) {
  let Menu = [];
  list.forEach((item) => {
    let menuItem = {};
    menuItem.id = item.id + "";
    menuItem.title = item.name;
    menuItem.icon = item.image_url;
    menuItem.secNavL = [];
    if (item.childrens && item.childrens.length > 0) {
      item.childrens.forEach((it) => {
        // //判断是不是老板账户
        // let dd = JSON.parse(localStorage.getItem("managerInfo")).mobile;
        // if (it.routeUrl == "/consumeList" && dd == "18658876977") {
        //   let subMenu = {};
        //   subMenu.id = it.id + "";
        //   subMenu.secTitle = it.name;
        //   subMenu.url = it.routeUrl;
        //   menuItem.secNavL.push(subMenu);
        // } else {
          
        // }
        let subMenu = {};
          subMenu.id = it.id + "";
          subMenu.secTitle = it.name;
          subMenu.url = it.routeUrl;
          menuItem.secNavL.push(subMenu);
      });
    }
    Menu.push(menuItem);
  });
  return Menu;
}
