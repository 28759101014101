<template>
  <div class="title">
    <span class="" @click="backToFirst()">{{nav.firstNav}}</span>
    <span>
    <i class='el-icon-arrow-right' />
    </span>
    <span @click="backToSecond()">{{nav.secondNav}}</span>
    <i v-if="nav.Nav" class='el-icon-arrow-right' />
    <span>{{nav.Nav}}</span>
    <i v-if="nav.NavS" class='el-icon-arrow-right' />
    <span>{{nav.NavS}}</span>
  </div>
</template>

<script>
export default {
  props:['nav'],
};
</script>

<style lang="less">

.title{
  font-size:18px;
  font-weight: 900;
  color: rgba(0,0,0,.7);
  height: 50px;
  font-family:'MicrosoftYaHei';
  background-color:#fff;
  @media screen and(max-width: 768px){
      font-size: 14px;
  }
  
}

.title i {
  margin:0 10px;
}
</style>