/**
 * 获取上传的图片key
 * @returns 
 */
export function getPicKeyName(){
    let str = 'hmpc'
    let range = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z','0','1','2','3','4','5','6','7','8','9']
    for(let i = 0;i < 5;i++){
        let r = Math.random()*35
        str += range[parseInt(r)]
    }
    let m = JSON.parse(localStorage.getItem('managerInfo'))
    if(m){
        str += m.id
    }
    return str + new Date().getTime()+'.png';
}



/**
 * 计算两个经纬度之间的距离
 * @param lat1
 * @param lng1
 * @param lat2
 * @param lng2
 * @returns distance 
 */
 export function getDistance(lat1, lng1, lat2, lng2) {
	var radLat1 = rad(lat1);
	var radLat2 = rad(lat2);
	var a = radLat1 - radLat2;
	var b = rad(lng1) - rad(lng2);
	var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math
		.pow(Math.sin(b / 2), 2)));
	s = s * 6378.137; // EARTH_RADIUS;
	s = Math.round(s * 10000) / 10000; //输出为公里
	var distance = s;
	if (s !== s) {
		return ''
	}
	var distance_str = '';
	if (parseInt(distance) >= 1) {
		distance_str = distance >= 100 ? '>100km' : distance.toFixed(1) + 'km';
	} else {
		distance_str = (distance * 1000).toFixed(0) + 'm';
	}
	return distance_str || "";
}

function rad(d) {
	return (d * Math.PI) / 180.0;
}