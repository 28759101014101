import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { Message } from "element-ui";
import router from "@/router/index.js";
import { ObjecToMenu } from "@/utils/menu.js";
Vue.use(Vuex);

let admin = JSON.parse(localStorage.getItem("managerInfo")) || {name:""}


const store = new Vuex.Store({
  state: {
    timLogin:false,
    // urlName: 'http://139.186.169.185:8768/eg-api',
    //用户中心数据
    userCenterData: {
      totalUser: null,
      androidYesterdayTotal: null,
      iosYesterdayTotal: null,
      boyCount: null,
      iosRegistTotal: null,
      ThirdPartyRegistTotal: null,
      girlCount: null,
      girlYesterdayCount: null,
      pcRegistTotal: null,
      pcYesterdayTotal: null,
      ThirdPartyYesterdayTotal: null,
      androidRegistTotal: null,
      boyYesterdayCount: null,
    },
    headerShow:true,
    menuShow:true,
    // boy
    //用户权限菜单
    menuList: [],
    permissionList: [],
    // 大屏幕数据
    screenInfos: {
      edu: {
        amount: "-",
        newAdd: "-",
      },
      sch: {
        amount: "-",
        newAdd: "-",
      },
      mec: {
        amount: "-",
        newAdd: "-",
      },
      tea: {
        amount: "-",
        newAdd: "-",
      },
      cla: {
        amount: "-",
        newAdd: "-",
      },
      use: {
        amount: "-",
      },
    },
    addrList: [],
    needLogin: true,
    // 智慧校园数据中心

    //ad
    isBlack: false,
    addBanner: false,
    addList: false,
    itemBannerOrList: {},
    stuGrade: {
      早教: 40,
      学前班: 3,
      "小学1-3": 2,
      "小学4-6": 0,
      初中: 0,
      高中: 1,
    },

    categories_child_list:[],
    admin_name:admin.name,

    signTemplate:{}


  },
  mutations: {
    timLogin(state, payload) {
      state.timLogin = payload;
    },
    setSignTemplate(state, payload) {
      state.signTemplate = payload;
    },
    setProp(state, obj) {
      state[obj.prop] = obj.value;
    },
    changeDistrictList(state, payload) {
      state.addrList = payload;
    },
    setMenu(state, payload) {
      state.menuList = payload;
    },
    setPermissionList(state, payload) {
      state.permissionList = payload;
    },
    setCateList(state, payload) {
      state.categories_child_list = payload;
    },
    setAdmin_name(state, payload) {
      state.admin_name = payload;
    },
  },
  actions: {
    // 获取级联地址
    getDistrictList({ commit }) {
      let url = "/user/district/query";

      if (!localStorage.getItem("addrList")) {
        axios.get(url).then((res) => {
          // this.addrList = payload.data;
          commit("changeDistrictList", res.data.data[0].childrens);
          let temp = JSON.stringify(res.data.data[0].childrens);
          localStorage.setItem("addrList", temp);
        });
      } else {
        let addrList = localStorage.getItem("addrList");
        let temp = JSON.parse(addrList);
        commit("changeDistrictList", temp);
      }
    },

    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("managerInfo");
      localStorage.removeItem("mechanism_id");
      localStorage.removeItem("menu");
      localStorage.removeItem("type");
      localStorage.removeItem("cooperator");
      localStorage.removeItem("agentAreas");
      localStorage.removeItem("cur_agentAreas");
      Message("登录过期")
      router.push('/login');
    },

    // 获取UserCount数据
    smartSchDataTotal({ commit }) {
      let url = "/user/mastermechanism/queryMechanismInfoBackCount";
      axios
        .get(url, {
          params: {
            start_time: new Date().Format("yyyy-MM-dd"),
          },
        })
        .then((res) => {
          commit("getSmartSchDataTotal", res);
        });
    },
    getCateList({ commit }) {
      return new Promise((resolve,reject)=>{
        let url = "/user/mechanismCategory/queryListPageChild";
        let data = {
          status: 2,
          type: 2,
          source: "课程",
        };
        axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            if(res.data.code == 0){
              let data = res.data.data;
              commit('setCateList',data)
              resolve(data)
            }else{
              reject()
            }
            
          });
      })
    },
    //获取用户的权限菜单
    getRoleMenu({ commit }) {
      return new Promise((resolve, reject) => {
        let params = {
          type: "haima_houtai",
          state: 1,
          id:
            JSON.parse(localStorage.getItem("managerInfo")).identity_id ||
            JSON.parse(localStorage.getItem("managerInfo")).admin_id,
        };
        axios({
          url: "/authorization/menu/queryMenuListByIdentityId",
          method: "get",
          params,
        })
          .then((res) => {
            const list = res.data.data;
            if (list == null) {
              localStorage.removeItem("token");
              localStorage.removeItem("managerInfo");
              localStorage.removeItem("mechanism_id");
              localStorage.removeItem("menu");
              router.push("/");
              Message({ message: "你没有访问权限" });
              window.localStorage.setItem("menu", JSON.stringify([]));
              return;
            }
            const menu = ObjecToMenu(list);
            window.localStorage.setItem("menu", JSON.stringify(menu));
            commit("setMenu", menu);
            resolve(menu);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //获取用户权限
    getUserPermission({ commit }) {
      return new Promise((resolve, reject) => {
        let params = {
          id: JSON.parse(localStorage.getItem("managerInfo")).identity_id || 1,
        };
        axios({
          url: "/authorization/apiUrl/queryRoleIdentity",
          method: "get",
          params,
        })
          .then((res) => {
            let list = []
            if( res.data.data instanceof Array){
             list = res.data.data.map((item) =>{ return item.url});
            }
            window.localStorage.setItem("perm", JSON.stringify(list));
            commit("setPermissionList", list);
            resolve(list);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 智慧校园内总数据
    getSmartSchData() {},
  },
  modules: {},
});
export default store;
