import router from './index'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import store from '@/store'

NProgress.configure({ showSpinner: false }) 

router.beforeEach((to, from, next) => {
  NProgress.start()
  let menuList = [];
  try {
    menuList = JSON.parse(localStorage.getItem("menu")) || [];
  } catch (error) {
    menuList = [];
  }
  const token = localStorage.getItem("token");
  let hasmenu = menuList.length > 0 ? true : false;
  //进入login直接return 防止死循环
  if(Object.is(to.name,'login')) {
    next();
    return
  }

  if(!token){
    router.push({ name: 'login' })
  }else{
    if(to.path == '/403'){
      NProgress.done()
      next()
    }else if(to.path != '/login'){
          if(to.meta && !to.meta.Permission){
            NProgress.done()
            next()
          }else{
            NProgress.done()
            has_Permission(menuList,to.path) ? next() :  router.push({ name: '403' })
          }
    }else if(to.path == '/login'){
      NProgress.done()
      console.log('to logion');
      next()
    }
  }

 
});

router.afterEach(()=>{
  NProgress.done()
})

function has_Permission(list, name) {
  name = name.replace("/main", "");
  // console.log(name);
  let res = false;
  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      let subMenu = list[i].secNavL;
      for (let j = 0; j < subMenu.length; j++) {
        if (subMenu[j].url == name) {
          res = true;
          return true;
        }
      }
    }
  }
  return res;
}