<template>
    <div>
        <el-select
              v-model="selected_user"
              :placeholder="placeholder || '请输入电话或姓名'"
              filterable
              remote
              :remote-method="getUser"
              clearable
              @change="changeUser"
              ref="agentSelectc"
            >
              <el-option
                v-for="item in coach_list"
                :key="item.id"
                :label="item.real_name"
                :value="item.id"
                @click.native="userChnage(item)"
              >
              <div>{{item.real_name}}-{{item.city}}</div>
              </el-option>
            </el-select>
    </div>
</template>

<script>
export default {
  props:{
    placeholder:{
      type:[String,Number],
      default:"请输入电话或姓名"
    },
    condition:{
      type:Object,
      default:()=>{return {}}
    }
  },
    data() {
        return {
            coach_list:[],
            selected_user:''
        };
    },
    methods: {
       clear(){
        this.coach_list= []
        this.selected_user= ""
      },
        changeUser(){
            this.$emit('update:coachid', this.selected_user);
        },
        userChnage(item){
          this.$emit('change', item);
        },
        getUser(query) {
        this.coach_list = [];
        const numberReg = /^\d/
        if (query && typeof query == "string") {
          let data={
            status:2,
            ...this.condition
          }
          numberReg.test(query)?(data.phone = query) : (data.real_name = query)
          let url = "/user/coach/queryListPage";
          this.$axios
            .get(url, {
              params: data,
            })
            .then((res) => {
              this.coach_list = res.data.data;
              this.$emit('search', res.data.data.rows);
            });
        }
      },
      clear(){
        this.coach_list= []
        this.selected_user= ""
      },
    },
};
</script>

<style lang="scss" scoped>

</style>