<template>
  <div>
    <el-select
      v-model="selected_user"
      :placeholder="placeholder"
      value-key="user_id"
      filterable
      remote
      :remote-method="getUser"
      clearable
      @change="changeUser"
      ref="agentSelect"
      @hook:mounted="cancalReadOnly"
      @visible-change="cancalReadOnly"
    >
      <el-option
        v-for="item in user_list"
        :key="item.user_id"
        :label="item.nick_name"
        :value="item.user_id"
        @click.native="userChnage(item)"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props:{
    placeholder:{
      type:String,
      default:"请输入电话或昵称"
    }
  },
  data() {
    return {
      user_list: [],
      selected_user: "",
    };
  },
  methods: {
    clear(){
        this.user_list= []
        this.selected_user= ""
      },
    changeUser() {
      this.$emit("update:userid", this.selected_user);
    },
    userChnage(item) {
      this.$emit("change", item);
    },
    getUser(query) {
      this.user_list = [];
      const numberReg = /^\d/
      if (query && typeof query == "string") {
        let data={}
        numberReg.test(query)?(data.login_name = query) : (data.nick_name = query)
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: data
          })
          .then((res) => {
            this.user_list = res.data.data.rows;
            this.$emit("search", res.data.data.rows);
          });
      }
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs;
        
          if (Selects.agentSelect) {
            const input =
              Selects.agentSelect.$el.querySelector(".el-input__inner");
            input.removeAttribute("readonly");
          } // 如果只有1个下拉框，这段就足够了---end // 如果有多个，就加多几个，代码可以优化，我懒了
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>