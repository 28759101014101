//设置请求的代理商代理区域参数
export const setAgentAreaInfo = (config) => {
    const type = localStorage.getItem("type") || ''
    
    if (['agent','sub_agent','agent_company'].includes(type)) {
        let area = JSON.parse(localStorage.getItem("cur_agentAreas") || '{}')

        for (let index = 0; index < patterns.length; index++) {
            const item = patterns[index];
            if (pathMatch(item, config.url)) {
                if (config.method == 'get') {
                    config.params = { ...config.params, ...area }
                } else if (config.method == 'post') {
                    config.data = { ...config.data, ...area }
                }
                return config
            }
        }
    }

    return config
}
//代理商要匹配城市信息的路径
const patterns = [
    '/user/rechargerecord/totalAssetsCount',
    '/user/coach/categoryGroup',
    '/user/mastermechanism/queryCountByTime',
    '/user/userRecommenderIncomeLog/queryMechanismEarningList',
    '/user/userAppointment/list/page',
    '/user/masterAppointment/settleCountByTime',
    'user/rechargerecord/queryTotalAll',
    '*/queryManagerListPage',
    '*/queryByMessage',
    '/user/user/newCountByTime',
'/user/user/newUserPlatformByTime',
'/user/coach/newCountByTime',
'/user/coach/countByRole',
'/user/coach/coachNewAndOnline',
'/user/user/userNewAndOnline'
   
]

/**
* 路径匹配
* @param pattern
* @param url
* @return true : 能匹配 false: 不匹配
*/
function pathMatch(pattern, url) {
    if (pattern === url) {
        return true;
    }
    
    if(pattern.includes("*")){
        let patternSplit = pattern.split("*")
        let s = patternSplit[0]
        let e = patternSplit[patternSplit.length - 1]

        let reg = new RegExp(`^${s}.*${e}$`,'ig') 
      return reg.test(url)
    }else{
        if(url.includes(pattern)){
            return true
        }
    }


    return false;
}



