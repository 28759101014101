<template>
  <div class="app-container">
    <el-popover
      placement="right"
      width="500"
      trigger="click"
      v-model="visiable"
    >
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item label="详细地址：" prop="address" width="600">
          <el-autocomplete
            v-model="form.address"
            popper-class="autoAddressClass"
            :fetch-suggestions="querySearchAsync"
            style="width: 280px"
            :trigger-on-focus="false"
            placeholder="详细地址"
            clearable
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <i class="el-icon-search fl mgr10" />
              <div style="overflow: hidden">
                <div class="title">{{ item.name }}</div>
                <span class="address ellipsis">{{ item.district }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="地图定位：">
        </el-form-item>
        <div id="map-container" style="width: 100%; height: 350px" />
        <div>
          点击地图内位置-点击提交-可获取经纬度
          <div
            @click="selectAddress(item)"
            v-for="(item, index) in tuijianList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="flex" style="justify-content: flex-end;margin-top:10px">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="visiable = false">取消</el-button>
        </div>
      </el-form>
      <el-button slot="reference" v-if="type == 'button'"
        >点击选择地址</el-button
      >
      <div slot="reference" v-if="type == 'icon'">
        <i class="el-icon-location-outline" style="font-size: 20px"></i>
      </div>
    </el-popover>
  </div>
</template>
  
  <script>
export default {
  name: "mapdemo",
  props: {
    type: {
      type: String,
      default: "button",
    },
  },
  data() {
    return {
      tuijianList: [],
      form: {
        address: "", // 详细地址
        addrPoint: {
          // 详细地址经纬度
          lng: 0,
          lat: 0,
        },
      },
      map: "", // 地图实例
      marker: "", // Marker实例
      geco:"",
      search:'',
      auto:"",
      locationPoint: null,
      visiable: false,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
   
    // 初始化地图
    initMap() {
      this.$map.then((AMap) => {
       let mapOption = {
          zoom: 14, //级别
          center: [120.227664, 30.269557], //中心点坐标
          viewMode: "3D", 
          scrollWheel: false,
        };

        this.map = new AMap.Map("map-container", mapOption);
        this.geco = new AMap.Geocoder();
        this.auto = new AMap.Autocomplete();
        this.search = new AMap.PlaceSearch();
         this.marker = new AMap.Marker();
       
        // 3、绑定点击地图任意点事件
        this.map.on("click",  (e)=> {
            let {lng,lat} = e.lnglat
            this.map.add(this.marker);
            this.marker.setPosition([lng,lat]);
            // map.setFitView(marker);
          this.getAddrByPoint([lng,lat]);
        });
      });
    },
    selectAddress(item) {
    console.log(item);

    let {lng,lat} = item.location
      this.currAddress = item;

      let that = this;
    this.marker.setPosition([lng,lat]);
        this.getAddrByPoint([lng,lat]);
      that.form.address = item.name;
      that.form.addrPoint = {lng,lat};
    },
    // 4、逆地址解析函数
    getAddrByPoint(point) {
      var that = this;
      that.form.addrPoint = {lng:point[0],lat:point[1]};
      this.geco.getAddress(point, function(status, result) {
            if (status === 'complete'&&result.regeocode) {
                console.log(result);
                that.form.province = result.regeocode.addressComponent.province;
                that.form.city = result.regeocode.addressComponent.city;
                that.form.district = result.regeocode.addressComponent.district;
                that.tuijianList = result.regeocode.addressComponent.businessAreas;
                that.form.address = result.regeocode.formattedAddress
            }else{
                console.log('根据经纬度查询地址失败')
            }
        });
    },
    // 8、地址搜索
    querySearchAsync(str, cb) {
        console.log(str);
        this.auto.search(str,(status,result)=>{
            console.log(result);
            cb(result.tips)
        })
    },
    // 8、选择地址
    handleSelect(item) {
        let {lng,lat} = item.location
      this.form.address = item.district + item.name;
      this.form.addrPoint = {lng,lat};
    this.map.add(this.marker);
    this.marker.setPosition([lng,lat]);
    this.map.setCenter([lng, lat])

    },
    onSubmit() {
      this.visiable = false;
      this.$emit("getform", this.form);
    },
  },
};
</script>
  
  <style  scoped>
.autoAddressClass li i.el-icon-search {
  margin-top: 11px;
}
.autoAddressClass li .mgr10 {
  margin-right: 10px;
}
.autoAddressClass li .title {
  text-overflow: ellipsis;
  overflow: hidden;
}
.autoAddressClass li .address {
  line-height: 1;
  font-size: 12px;
  color: #b4b4b4;
  margin-bottom: 5px;
}
.app-container >>> .BMap_cpyCtrl {
  display: none;
}
.app-container >>> .anchorBL {
  display: none;
}
</style>
  
  