import axios from "axios";
import { Message } from 'element-ui';
import store from "../store";
import {setAgentAreaInfo} from './filter';
let http  =  process.env.VUE_APP_BASE_URL



axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.interceptors.request.use(
  function (config) {
    // if(JSON.parse(localStorage.getItem("managerInfo")|| '{}').city){
    //   config.params.city = JSON.parse(localStorage.getItem("managerInfo")).city
    // }
    setAgentAreaInfo(config)
    config.headers.token = localStorage.getItem("token"); 
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if(response.config.url.includes("qiniup.com")){
      return Promise.resolve(response);
    }
    // token过期，重返登录界面
    if (response.data.code === 30003) {
      store.dispatch("logout")
    }
    if(response.data === 1){
      return Promise.resolve(response);
    }
    if (response.data.code === 0) {
      return Promise.resolve(response);
    }else{
      Message({
        type: "error",
        message: response.data.message,
      });
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.message == "canceled") {
    } else {
      Message({
        type: "error",
        message: error.message,
      });
    }
    return Promise.reject(error);
  }
);
function get(url, data) {
  return axios({
    url: url,
    method: "GET",
    params: data,
  });
}
function post(url, data) {
  return axios({
    url: url,
    method: "POST",
    data: data,
  });
}
export default {
  get,
  post,
};


 