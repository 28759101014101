<template>
  <div class="addr-select">
    <span class="demonstration" style="font-size: 1rem">省份</span>
    <el-select v-model="province" clearable :placeholder="addr.province">
      <el-option disabled label="请选择省份" :value="null"></el-option>
      <el-option v-for="(item, index) in this.$store.state.addrList" :key="index" :label="item.district"
        :value="[index, item.district]"></el-option>
    </el-select>
    <span class="demonstration" style="font-size: 1rem">城市</span>
    <el-select v-model="city" :disabled="province == 0" clearable :placeholder="addr.city">
      <el-option disabled label="请选择城市" :value="null"></el-option>
      <el-option v-for="(item, index) in AllCities" :key="index" :label="item.district" :value="[index, item.district]">
      </el-option>
    </el-select>
    <span class="demonstration" style="font-size: 1rem">县区</span>
    <el-select v-model="district" :disabled="city == 0" clearable :placeholder="addr.district">
      <el-option disabled label="请选择县区" :value="null"></el-option>
      <el-option v-for="(item, index) in AllDistricts" :key="index" :label="item.district"
        :value="[index, item.district]"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    clear: {
      type: Boolean,
    },
    addr: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      province: [],
      city: [],
      district: [],
      ifClear: false,
    }
  },
  computed: {
    AllCities() {
      let arr = []
      for (let i = 0; i < this.$store.state.addrList.length; i++) {
        arr.push(this.$store.state.addrList[i].childrens)
      }
      // console.log(arr);
      // console.log(this.province);
      // console.log(arr[this.province[0]]);
      return this.province ? arr[this.province[0]] : [{ district: '请选择省份' }]
    },
    AllDistricts() {
      if (this.city && this.AllCities && this.AllCities[0].district != '请选择省份') {
        return this.AllCities[this.city[0]].childrens
      }
      return [{ district: '请选择城市' }]
    },
  },

  watch: {
    province(newV, oldV) {
      if (newV != '') {
        console.log('province', this.province)
        this.$emit('getAddr', {
          province: this.province[1],
          city: null,
          district: null,
        })
      } else if (newV != oldV) {
        console.log('province', this.province)
        this.$emit('getAddr', {
          province: null,
          city: null,
          district: null,
        })
        console.log(1)
        this.city = ''
      }
    },
    city(newV, odlV) {
      if (newV != '') {
        console.log('city', this.city)
        this.$emit('getAddr', {
          province: this.province[1],
          city: this.city[1],
          district: null,
        })
      } else if (this.province && newV != odlV) {
        console.log('city', this.city)
        this.$emit('getAddr', {
          province: this.province[1],
          city: null,
          district: null,
        })
        console.log(1)
        this.district = ''
      }
    },
    district(newV, oldV) {
      if (newV != '') {
        console.log('district', this.district)
        this.$emit('getAddr', {
          province: this.province[1],
          city: this.city[1],
          district: this.district[1],
        })
      } else if (this.city && newV != oldV) {
        console.log('district', this.district)
        this.$emit('getAddr', {
          province: this.province[1],
          city: this.city[1],
          district: null,
        })
        console.log(1)
      }
    },
    clear(newV, oldV) {
      if (newV == true) {
        this.province = ''
      }
    },
  },
}
</script>

<style>
.addr-select {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.addr-select .el-select {
  width: 8rem;
}
</style>