import store from '@/store'

function checkPermission(el,bind){
    const permissionList = store.state.permissionList
    const {value} = bind

    if(value && permissionList instanceof Array){
        if(!permissionList.includes(value)){
            el.parentNode && el.parentNode.removeChild(el)
        }
        }
    }

export default {
    inserted(el, binding) {
      checkPermission(el, binding)
    },
    update(el, binding) {
      checkPermission(el, binding)
    }
  }