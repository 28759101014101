<template>
  <el-row>
    <!-- <school-total v-if="count" :orgType="orgType"></school-total> -->
    <el-row>
      <div class="sec-title" style="" v-if="title">区域分布地图</div>
      <div class="">
        <el-form :inline="true"  class="demo-form-inline">
        <el-form-item label="机构名称">
          <el-input  placeholder="机构名称" v-model="mecName"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <addr-select @getAddr="getAddr"></addr-select>
        </el-form-item>
        <el-form-item label="类别">
          <el-cascader v-model="selectCate" :props="props" :options="sportMaster" @change="handleChange" clearable>
             <template slot-scope="{ data }">
                <span>{{ data.name }}</span>
             </template>
          </el-cascader>
        </el-form-item>
        <el-form-item>
            <el-button @click="search()" type="primary">搜索</el-button>
        </el-form-item>
      </el-form>
      </div>
    </el-row>
    <div id="mapContainer" style="height: 56.91666667rem; width: 112.5rem"></div>
  </el-row>
</template>

<script>
import schoolTotal from '../components/dataBoard/schoolTotal.vue'
import addrSelect from '../components/addrSelect.vue'
export default {
  props: ['orgType', 'title', 'count'],
  components: {
    schoolTotal,
    addrSelect
  },
  data() {
    return {
      value: [],
      MarkerList: [],
      mapInfos: [],
      if_clear: false,
      sportMaster:[],
      sportDetail:[],
      sportValue:[],
      selectCate:'',
      mecName:'',
      props: {
          lazy: true,
          value:'name',
          label:'name',
          lazyLoad: (node, resolve)=> {
            let url="user/mechanismCategory/queryManagerListPage"
            this.$axios({
              url,
              params:{
                type:2,
                parent_id:node.data?.id,
                status:2
              }
            }).then(res=>{
              resolve(res.data?.data?.rows.map(item=>{
                item.leaf =true
                return item
              }))
            })
          }
        }
    }
  },
  methods: {
    getmechanismCategory(){
      let url="user/mechanismCategory/queryManagerListPage"
      this.$axios({
        url,
        params:{
          type:1
        }
      }).then(res=>{
        this.sportMaster=res.data.data.rows
      })
    },
    handleChange(value){
      this.sportValue=value
    },
    open() {
      this.$message({ type: 'error', message: '所选区域无数据!' })
    },
    getAddr(val) {
      this.value = val
    },
    
    search(){
      let url='user/mastermechanism/queryMechanismByTypeSimpSearch'
      let params = {
        currentPage:1,
        pageSize:20,
        mechanism_name:this.mecName,
        sortName:'id',
        type:'teach_paypal' ,
        card_type:'成长卡',
        province:this.value.province,
        city:this.value.city,
        district:this.value.district,
        categories:this.sportValue[0],
        categories_child:this.sportValue[1]
      }
      this.$axios({
        url,
        params
      }).then(res=>{
        if(res.data.code==0){
          if(res.data.data.length>0){
              this.mapInfos=res.data.data
              this.drawMap()
          }else{
              this.$message({
                type: 'success',
                message: '查询结果为空',
              })
          }   
        }else{
             this.$message({
                type: 'error',
                message: res.data.message,
              })
        }
      })
    },
    getMapInfos() {
      let url = '/user/mastermechanism/queryMechanismMapList'
      let options = {}
      if (this.orgType == 'total') {
        options = {
          params: {
            type: 'teach_paypel',
            // is_cooperation: true,
            status: 2,
            province: this.value.province ? this.value.province : null,
            city: this.value.city ? this.value.city : null,
            district: this.value.district ? this.value.district : null,
          },
        }
      } else {
        options = {
          params: {
            type: 'teach_paypal',
            // is_cooperation: true,
            status: 2,
            province: this.value.province,
            city: this.value.city,
            district: this.value.district,
          },
        }
      }
      this.$axios.get(url, options).then((res) => {
        if(res.data.data.length>0){
            res.data.data.forEach((e,i) => {
              if(e.longitude==0 || e.latitude==0){
                res.data.data.splice(i,1)
              }
          });
        }  
        this.mapInfos = res.data.data
        if (this.mapInfos == 0) {
          this.open()
        }
        this.drawMap()
      })
    },
    drawMap() {
      let that = this
      this.$map.then((AMap) => {
        let mapOption = {}
        if (that.mapInfos.length > 0) {
          // console.log(that.mapInfos);
          mapOption = {
            zoom: 16, //级别
            center: [that.mapInfos[0].longitude, that.mapInfos[0].latitude],
            //中心点坐标
            viewMode: '3D', //使用3D视图,
            // scrollWheel: false,
          }
        } else {
          mapOption = {
            zoom: 14, //级别
            center: [120.227664, 30.269557], //中心点坐标
            viewMode: '3D', //使用3D视图,
            scrollWheel: false,
          }
        }
        let map = new AMap.Map('mapContainer', mapOption)

        // that.mapInfos = that.mapInfos.slice(1, 20)
        var markers = []
        var allowCollision = false
        var layer = new AMap.LabelsLayer({
          zooms: [3, 20],
          zIndex: 1000,
          // collision: false,
          // 设置 allowCollision：true，可以让标注避让用户的标注
          allowCollision,
        })
        layer.add(markers)
        // 图层添加到地图
        map.add(layer)
        var icon = {
          // 图标类型，现阶段只支持 image 类型
          type: 'image',
          // 图片 url
          image: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
          // 图片尺寸
          size: [19, 24],
          // 图片相对 position 的锚点，默认为 bottom-center
          anchor: 'center',
        }
        // 初始化 labelMarker
        for (var i = 0; i < that.mapInfos.length; i++) {
          var curData = {
            id: that.mapInfos[i].id,
            position: [that.mapInfos[i].longitude, that.mapInfos[i].latitude],
            name: that.mapInfos[i].mechanism_name,
            icon,
            text: {
              content: that.mapInfos[i].mechanism_name,
              direction: 'top',
            },
          }
          curData.extData = {
            index: i,
          }

          var labelMarker = new AMap.LabelMarker(curData)
          labelMarker.on('click', function (e) {
            console.log(e)
            // 添加点击事件
            that.$router.push({
              name: 'mecDetails',
              query: { mecID: e.data.data.id },
            })
          })
          markers.push(labelMarker)
        }
        // 将 marker 添加到图层
        layer.add(markers)

        map.setFitView(null, false, [100, 150, 10, 10])

      })
      // .catch((e) => {
      //   console.log(e);
      // });
    },
    // search() {
    //  this.mapInfos= this.mapInfos.filter(item=>item.mechanism_name.includes(this.mecName))
    //   // this.getMapInfos()
    //   this.drawMap()
    // },
  },
  created() {
    this. getmechanismCategory()
    this.getMapInfos()
    this.drawMap()
  },
  watch: {
    // mapInfos: function (newV, oldV) {
    //   this.drawMap();
    // },
  },
}
</script>

<style>
</style>